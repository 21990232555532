export var SearchUSPType;
(function (SearchUSPType) {
    SearchUSPType["AtolProtected"] = "atol-protected";
    SearchUSPType["LowestProceGuarantee"] = "lowest-price-guarantee";
    SearchUSPType["AbtaMember"] = "abta-member";
    SearchUSPType["ItaaMember"] = "itaa-member";
    SearchUSPType["TrustpilotConfidence"] = "trustpilot-confidence";
})(SearchUSPType || (SearchUSPType = {}));
export var DiscountTypes;
(function (DiscountTypes) {
    DiscountTypes[DiscountTypes["mobileDiscount"] = 2] = "mobileDiscount";
})(DiscountTypes || (DiscountTypes = {}));
export var productType;
(function (productType) {
    productType[productType["adult"] = 10] = "adult";
    productType[productType["child"] = 11] = "child";
})(productType || (productType = {}));
export var Funnel;
(function (Funnel) {
    Funnel[Funnel["HOTELHOLIDAY"] = 0] = "HOTELHOLIDAY";
    Funnel[Funnel["TRANSFER"] = 1] = "TRANSFER";
    Funnel[Funnel["ATTRACTION"] = 2] = "ATTRACTION";
})(Funnel || (Funnel = {}));
export var BookingPageType;
(function (BookingPageType) {
    BookingPageType[BookingPageType["PASSENGERS"] = 0] = "PASSENGERS";
    BookingPageType[BookingPageType["PAYMENT"] = 1] = "PAYMENT";
    BookingPageType[BookingPageType["TRANSFERBOOKING"] = 2] = "TRANSFERBOOKING";
})(BookingPageType || (BookingPageType = {}));
export var FlightType;
(function (FlightType) {
    FlightType["OUTBOUND"] = "outbound";
    FlightType["INBOUND"] = "inbound";
})(FlightType || (FlightType = {}));
export var AirportType;
(function (AirportType) {
    AirportType["DESTINATION_AIRPORT_CODE"] = "destinationAirportCode";
    AirportType["DESTINATION_AIRPORT_NAME"] = "destinationAirportName";
    AirportType["DEPARTURE_AIRPORT_CODE"] = "departureAirportCode";
    AirportType["DEPARTURE_AIRPORT_NAME"] = "departureAirportName";
})(AirportType || (AirportType = {}));
export var FlightTime;
(function (FlightTime) {
    FlightTime["OUTBOUND_DEPARTURE_FLIGHT_TIME_HOURS"] = "outboundDepartureFlightTimeHours";
    FlightTime["OUTBOUND_DEPARTURE_FLIGHT_TIME_MINUTES"] = "outboundDepartureFlightTimeMinutes";
    FlightTime["INBOUND_ARRIVAL_FLIGHT_TIME_MINUTES"] = "inboundArrivalFlightTimeMinutes";
    FlightTime["INBOUND_ARRIVAL_FLIGHT_TIME_HOURS"] = "inboundArrivalFlightTimeHours";
})(FlightTime || (FlightTime = {}));
export var FlightDate;
(function (FlightDate) {
    FlightDate["OUTBOUND_DEPARTURE_DATE"] = "outboundDepartureDate";
    FlightDate["INBOUND_ARRIVAL_DATE"] = "inboundArrivalDate";
})(FlightDate || (FlightDate = {}));
export var ProductType;
(function (ProductType) {
    ProductType[ProductType["SEAT"] = 461] = "SEAT";
})(ProductType || (ProductType = {}));
export var BookingExtraType;
(function (BookingExtraType) {
    BookingExtraType[BookingExtraType["BAG"] = 0] = "BAG";
    BookingExtraType[BookingExtraType["SEAT"] = 1] = "SEAT";
})(BookingExtraType || (BookingExtraType = {}));
export var ErrorTypeEnum;
(function (ErrorTypeEnum) {
    ErrorTypeEnum["Unexpected"] = "unexpected";
    ErrorTypeEnum["Prepayment"] = "prepayment";
    ErrorTypeEnum["Postpayment"] = "postpayment";
    ErrorTypeEnum["Validation"] = "validation";
    ErrorTypeEnum["Error"] = "";
})(ErrorTypeEnum || (ErrorTypeEnum = {}));
export var InsuranceRelationshipId;
(function (InsuranceRelationshipId) {
    InsuranceRelationshipId[InsuranceRelationshipId["INDIVIDUAL"] = 1] = "INDIVIDUAL";
    InsuranceRelationshipId[InsuranceRelationshipId["COUPLE"] = 2] = "COUPLE";
    InsuranceRelationshipId[InsuranceRelationshipId["SINGLE_PARENT_FAMILY"] = 3] = "SINGLE_PARENT_FAMILY";
    InsuranceRelationshipId[InsuranceRelationshipId["FAMILY"] = 4] = "FAMILY";
})(InsuranceRelationshipId || (InsuranceRelationshipId = {}));
export var SchemaLinkType;
(function (SchemaLinkType) {
    SchemaLinkType["Faq"] = "https://schema.org/FAQPage";
    SchemaLinkType["Question"] = "https://schema.org/Question";
    SchemaLinkType["Answer"] = "https://schema.org/Answer";
    SchemaLinkType["Hotel"] = "https://schema.org/Hotel";
    SchemaLinkType["PostalAddress"] = "https://schema.org/PostalAddress";
    SchemaLinkType["GeoCoordinates"] = "https://schema.org/GeoCoordinates";
    SchemaLinkType["AggregateRating"] = "https://schema.org/AggregateRating";
    SchemaLinkType["Review"] = "https://schema.org/Review";
    SchemaLinkType["Person"] = "https://schema.org/Person";
    SchemaLinkType["Rating"] = "https://schema.org/Rating";
})(SchemaLinkType || (SchemaLinkType = {}));
export var ItemPropType;
(function (ItemPropType) {
    ItemPropType["AcceptedAnswer"] = "acceptedAnswer";
    ItemPropType["MainEntity"] = "mainEntity";
    ItemPropType["Name"] = "name";
})(ItemPropType || (ItemPropType = {}));
export var HotelRatingType;
(function (HotelRatingType) {
    HotelRatingType["Worst"] = "1";
    HotelRatingType["Best"] = "10";
})(HotelRatingType || (HotelRatingType = {}));
