var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ModularPrice, ModularPriceCurrency, ModularPriceMajor, ModularPriceFraction, ModularPriceFormatted, } from '@honeycomb/ui-core';
import React from 'react';
function getMoneyPartModules(parts) {
    let currency = '';
    let major = '';
    let fraction = '';
    let sign = '';
    parts.forEach((part) => {
        const { type, value } = part;
        if (type === 'currency' || type === 'literal') {
            currency += value;
            return;
        }
        if (type === 'integer' || type === 'group') {
            major += value;
            return;
        }
        if (type === 'decimal' || type === 'fraction') {
            fraction += value;
        }
        if (type === 'plusSign' || type === 'minusSign') {
            sign += value;
        }
    });
    // Prepare the results:
    // We know that fraction always follows major.
    const result = [
        { type: 'major', value: major },
        { type: 'fraction', value: fraction },
    ];
    // Currency may be either first or last.
    const currencyFirst = parts[0].type === 'currency' || parts[1].type === 'currency';
    result[currencyFirst ? 'unshift' : 'push']({ type: 'currency', value: currency });
    if (parts[0].type === 'plusSign' || parts[0].type === 'minusSign') {
        result.unshift({ type: parts[0].type, value: sign });
    }
    return result;
}
export function StyledMoney(_a) {
    var { parts, formatted } = _a, other = __rest(_a, ["parts", "formatted"]);
    // Group the parts into their modules for rendering
    const modules = React.useMemo(() => getMoneyPartModules(parts !== null && parts !== void 0 ? parts : []), [parts]);
    return (React.createElement(ModularPrice, Object.assign({}, other),
        React.createElement(ModularPriceFormatted, null, formatted),
        ";", modules === null || modules === void 0 ? void 0 :
        modules.map((module) => {
            if (module.type === 'major') {
                return React.createElement(ModularPriceMajor, { key: "major" }, module.value);
            }
            if (module.type === 'fraction') {
                return React.createElement(ModularPriceFraction, { key: "fraction" }, module.value);
            }
            if (module.type === 'currency') {
                return React.createElement(ModularPriceCurrency, { key: "currency" }, module.value);
            }
            if (module.type === 'plusSign' || module.type === 'minusSign') {
                return React.createElement(ModularPriceCurrency, { key: "sign" }, module.value);
            }
            return null;
        })));
}
